import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { renderToStaticMarkup } from 'react-dom/server';

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./home.css";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";

// import soru ile compenant soru sirasinin ayni olmasi onemli yoksa slayt bozuluyor
import Carousel from "../components/Carousel";

import { EffectFade, Navigation, Pagination, Keyboard } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";


const Eligard = () => {
  const [posts, setPosts] = useState([]);
  //const [postsh, setPostsh] = useState([]);
  const [sonuc01goster, setSonuc01] = useState([]);
  const [sonuc02goster, setSonuc02] = useState([]);
  const [sonuc03goster, setSonuc03] = useState([]);
  const [sonuc04goster, setSonuc04] = useState([]);
  const [sonuc051goster, setSonuc051] = useState([]);
  const [sonuc052goster, setSonuc052] = useState([]);
  const [sonuc06goster, setSonuc06] = useState([]);
  const [sonuc07goster, setSonuc07] = useState([]);
  const location = useLocation();
  const postId = location.pathname.split("/")[2];
  const records = posts.slice();
  //const recordsh = postsh.slice();
  const sonuc19 = 19;
  const sonuc24 = 24;
  const sonuc11 = 11;

  useEffect(() => {
  
  // sunum bilgileri
    const fetchData = async () => {
      //console.log("use effect home calisti");
      try {
        //console.log("postId", postId);
        const res = await axios.get(
          `https://api.recordatifocus.com/sunum-bilgileri/${postId}`
        );
        //console.log("res", res.data);
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
// sunum bilbileri
// 
// Soru 01
    const soruBir = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/eligard-sonuc-01/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru01 yanlis orani:", sonuc);

      if (sonuc >= sonuc19) {
      	const sonuc01goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_15.png"/></div>;
      	setSonuc01(sonuc01goster);
        //console.log(sonuc01goster);
      } else {
      	const sonuc01goster = null;
      	setSonuc01(sonuc01goster);
      }
    };
  
    soruBir();
// soru 01
// 
// 
// 
// Soru 02
    const soruIki = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/eligard-sonuc-02/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru02 yanlis orani:", sonuc);

      if (sonuc >= sonuc19) {
      	const sonuc02goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_10.png"/></div>;
      	setSonuc02(sonuc02goster);
        //console.log(sonuc01goster);
      } else {
      	const sonuc02goster = null;
      	setSonuc02(sonuc02goster);
      }
    };
  
    soruIki();
// soru 02
// 
// 
// 
// Soru 03
    const soruUc = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/eligard-sonuc-03/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru03 yanlis orani:", sonuc);

      if (sonuc >= sonuc11) {
      	const sonuc03goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_11.png"/></div>;
      	setSonuc03(sonuc03goster);
        //console.log(sonuc01goster);
      } else {
      	const sonuc03goster = null;
      	setSonuc03(sonuc03goster);
      }
    };
  
    soruUc();
// soru 03
// 
// 
// 
// Soru 04
    const soruDort = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/eligard-sonuc-04/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru04 yanlis orani:", sonuc);

      if (sonuc >= sonuc11) {
      	const sonuc04goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_12.png"/></div>;
      	setSonuc04(sonuc04goster);
        //console.log(sonuc01goster);
      } else {
      	const sonuc04goster = null;
      	setSonuc04(sonuc04goster);
      }
    };
  
    soruDort();
// soru 04
// 
// 
// 
// Soru 051
    const soruBesBir = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/eligard-sonuc-05/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru051 yanlis orani:", sonuc);

      if (sonuc >= sonuc19) {
      	const sonuc051goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_13.png"/></div>;
      	setSonuc051(sonuc051goster);
        //console.log(sonuc051goster);
      } else {
      	const sonuc051goster = null;
      	setSonuc051(sonuc051goster);
      }
    };
  
    soruBesBir();
// soru 051
// 
// 
// 
// Soru 052
    const soruBesIki = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/eligard-sonuc-05/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru052 yanlis orani:", sonuc);

      if (sonuc >= sonuc19) {
      	const sonuc052goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_14.png"/></div>;
      	setSonuc052(sonuc052goster);
        //console.log(sonuc01goster);
      } else {
      	const sonuc052goster = null;
      	setSonuc052(sonuc052goster);
      }
    };
  
    soruBesIki();
// soru 052
// 
// 
// 
// Soru 06
    const soruAlti = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/eligard-sonuc-06/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru06 yanlis orani:", sonuc);

      if (sonuc >= sonuc11) {
      	const sonuc06goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_17.png"/></div>;
      	setSonuc06(sonuc06goster);
        //console.log(sonuc01goster);
      } else {
      	const sonuc06goster = null;
      	setSonuc06(sonuc06goster);
      }
    };
  
    soruAlti();
// soru 06
// 
// 
// 

// Soru 07
    const soruYedi = async () => {
      const postId = location.pathname.split("/")[2];

      //console.log("postId", postId);
      const url = `https://api.recordatifocus.com/eligard-sonuc-07/${postId}`;
      const res = await fetch(url);
      const data = await res.json();
      let outputData = data.map((item) => {
        return {
          cevap: parseInt(item.cevap),
        };
      });
      //console.log(outputData);
      const result = Object.values(outputData).reduce(
        (r, { cevap }) => r + cevap,
        0
      );
      //console.log(result);
      const longeur = data.length;
      //console.log(longeur);
      //setPostsh(data);
      //console.log((result*100)/longeur);

      let sonuc = 100 - (result * 100) / longeur;
      console.log("Soru07 yanlis orani:", sonuc);

      if (sonuc >= sonuc11) {
      	const sonuc07goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_19.png"/></div>;
      	setSonuc07(sonuc07goster);
        //console.log(sonuc07goster);
      } else {
      	const sonuc07goster = null;
      	setSonuc07(sonuc07goster);
      }
    };
  
    soruYedi();
// soru 07
// 
// 
// 

  
  
  }, [postId]);


const slide01goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_01.png"/></div>;
const slide02goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_02.png"/></div>;
const slide03goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_03.png"/></div>;
const slide04goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_04.png"/></div>;
const slide05goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_05.png"/></div>;
const slide06goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_06.png"/></div>;
const slide07goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_07.png"/></div>;
const slide08goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_08.png"/></div>;
const slide09goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_09.png"/></div>;
const slide10goster = sonuc02goster;
const slide11goster = sonuc03goster;
const slide12goster = sonuc04goster;
const slide13goster = sonuc051goster;
const slide14goster = sonuc052goster;
const slide15goster = sonuc01goster;
const slide16goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_16.png"/></div>;
const slide17goster = sonuc06goster;
const slide18goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_18.png"/></div>;
const slide19goster = sonuc07goster;
const slide20goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_20.png"/></div>;
const slide21goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_21.png"/></div>;
const slide22goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_22.png"/></div>;
const slide23goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_23.png"/></div>;
const slide24goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_24.png"/></div>;
const slide25goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_25.png"/></div>;
const slide26goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_26.png"/></div>;
const slide27goster = <div><img className="img-fluid" src="https://slide.recordatifocus.com/upload/eligard/Eligard_Sunum_PTM_MART24_Page_27.png"/></div>;

const slides = [slide01goster, slide02goster, slide03goster, slide04goster, slide05goster, slide06goster, slide07goster, slide08goster, slide09goster, slide10goster, slide11goster, slide12goster, slide13goster, slide14goster, slide15goster, slide16goster, slide17goster, slide18goster, slide19goster, slide20goster, slide21goster, slide22goster, slide23goster, slide24goster, slide25goster, slide26goster, slide27goster];
var filtered = slides.filter(Boolean);
const listItems = filtered.map((number) =>  <SwiperSlide>{number}</SwiperSlide>);

  return (
    <>
      <Swiper
        effect={"fade"}
        pagination={{
          type: "fraction",
        }}
        keyboard={{
          enabled: true,
        }}
        navigation={true}
        modules={[EffectFade, Navigation, Pagination, Keyboard]}
        className="mySwiper"
      >
        {listItems}
      </Swiper>
  
      {records.map((post) => (
        <div className="container d-none my-4" key={post.id}>
          <div className="sunum-adi">Sunum Adi: {post.title}</div>
  		  <div className="sunum-adi">Sunum Tarihi: {post.tarih}</div>
  		  <div className="sunum-adi">Sunan Kisi: {post.uid}</div>
        </div>
      ))}

<div className={`col d-flex flex-column align-items-center justify-content-center pb-5 footer-${location.pathname.split("/")[2]}`}>
  <div className="card mx-2" style={{width: "6rem"}}>
  <a href="https://qr.recordatifocus.com/eligardkub" className="text-decoration-none text-black" target="_blank"><img alt="" src="https://sorular.recordatifocus.com/sites/api.recordatifocus.com/themes/bootstrap_dofo/images/KUB-ELIGARD.png" className="img-fluid" /></a>
  <div className="p-1" style={{backgroundColor: "#F09D8E"}}>
    <a href="https://qr.recordatifocus.com/eligardkub" className="text-decoration-none text-black" target="_blank"><div className="text-center fw-bold">ELIGARD</div></a>
  </div>
</div>
<p class="pb-5 mx-3 fw-bold footer-link-undefined">www.recordati.com.tr</p>
</div>

  
    </>
  );
};

export default Eligard;