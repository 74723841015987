import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Hippurin from "./pages/Hippurin";
import Eligard from "./pages/Eligard";
import Colchicum from "./pages/Colchicum";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";

function App() {
  const queryClient = new QueryClient()
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/hippurin/:id" element={<Hippurin />} />
            <Route path="/eligard/:id" element={<Eligard />} />
            <Route path="/colchicum/:id" element={<Colchicum />} />
          </Routes>
        </Router>
      </div>
    </QueryClientProvider>
  );
}

export default App;
